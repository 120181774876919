<template>
  <div class="page">
    <!--<el-carousel indicator-position="outside" :height="casHeight" :interval="5000">-->
      <!--<el-carousel-item v-for="(item,index) in picList" :key="index">-->
        <!--<el-image-->
          <!--:src="item.src"-->
          <!--style="width: 100%; height: 100%"-->
        <!--&gt;</el-image>-->
      <!--</el-carousel-item>-->
    <!--</el-carousel>-->
    <img src="../../assets/images/caseBG.png" class="backgroundPic">
    <div class="flexBoxCol">
      <div class="p48-400 mtp50 mbm50">典型案例</div>
      <div class="boxHomeLine mbm50" v-for="(caseItem, index) in caseList" :key="index">
        <img :src="caseItem.pic" class="homePic mrt50">
        <div>
          <div class="p24 boxHomeLineTitle">{{caseItem.name}}</div>
          <div class="span16 boxHomeLineText">
            <p>{{caseItem.desc1}}</p>
            <p>{{caseItem.desc2}}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      casHeight: '500px',
      picList: [
        {
          src: require('../../assets/images/caseBG.png')
        }
      ],
      caseList: [
        {
          name: '大祥一中',
          pic: require('@/assets/images/daxiangCase.jpg'),
          desc1: '大祥区第一实验中学正式成立于2005年2月，是原大祥区一中（西区一中）、二纺机子弟学校与湘印机子弟学校初中' +
              '部强强联合组建而成。占地26亩，学校30个教学班的规模，在校学生2100余人。',
          desc2: '学校引进斯点智慧校园后，通过闸机通行机器和电子学生证的铺设，解决了家长们的通话需求，极大提升了校方的安全管理能力。'
        },
        {
          name: '新渡学校',
          pic: require('@/assets/images/xinduCase.png'),
          desc1: '新渡小学位于邵阳市大祥区，占地面积约20亩，学校有16个教学班级，在校师生约1000人。',
          desc2: '学校引进斯点智慧校园后，满足了家长和学生之间的电话沟通需求，促进了家长和老师之间的沟通，提升了学校的美誉度，家长放心，老师安心！'
        },
        {
          name: '三八亭小学',
          pic: require('@/assets/images/38Case.png'),
          desc1: '三八亭小学占地36亩，学校30个教学班的规模，在校学生2000余人。',
          desc2: '学校引进斯点智慧校园后，提高了学校的管理效率，增加了老师和家长之间的沟通，让学校更加智能化、现代化。'
        },
        {
          name: '祥凤实验学校',
          pic: require('@/assets/images/xiangfengCase.png'),
          desc1: '祥凤实验学校为全国青少年校园足球特色学校，学校55个教学班的规模，在校学生2600余人。',
          desc2: '学校原本无智能出入管理系统，引进智慧校园后，完善了对各大校门出入口的管理建设，实现了无感自动化考勤，进出信息自动推送，在不影响校园通行效率的情况下强化了校园安全建设。'
        },
        {
          name: '百春园小学',
          pic: require('@/assets/images/baichunyuanCase.png'),
          desc1: '百春园小学约有师生1000人。',
          desc2: '校园引进了无感面部测温仪、人脸识别系统，增强了校园安全性，赋能学校出入管理能力。'
        },
        {
          name: '城南小学',
          pic: require('@/assets/images/chengnanCase.png'),
          desc1: '三八亭小学占地36亩，学校30个教学班的规模，在校学生2000余人。',
          desc2: '学校引进斯点智慧校园后，提高了学校的管理效率，增加了老师和家长之间的沟通，让学校更加智能化、现代化。'
        }
      ]
    }
  }
}
</script>
<style scoped lang="less">
  .boxHomeLine {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    padding: 50px;
    width: 85%;
    margin-bottom: 30px;
    border: 1px #BBBBBB solid;
    border-radius: 10px;
    box-shadow: 5px 5px 3px rgba(0,0,0,0.3)
  }

  .boxHomeLineTitle {
    text-align: left;
    margin-bottom: 20px;
  }
  .boxHomeLineText {
    text-align: left;
    letter-spacing: 0.1em;
    line-height: 30px;
    text-indent: 2em;
  }
  .homePic {
    width: 683px;
    height: 385px;
  }
</style>
